<template>
    <img src="./../assets/preloader.svg" alt="">
</template>

<script>
    export default {
        name: "Preloader"
    }
</script>

<style scoped>
    img {
        max-height: 50px;
        max-width: 50px;
        margin: 0 auto;
    }
</style>
