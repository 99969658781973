import Vue from 'vue'
import VueRouter from 'vue-router'
import {store} from './../vuex/store'
import DashboardLayout from '../layout/DashboardLayout.vue'
// GeneralViews
import NotFound from '../pages/NotFoundPage.vue'
import Error500 from '../pages/Error500.vue'
// Admin pages
import Icons from './../pages/Icons.vue'

Vue.use(VueRouter);


import AuthRouter from './parts/auth'
import UserRouter from './parts/user'
import CategoryRouter from './parts/category'
import TagRouter from './parts/tag'
import CaseRouter from './parts/case'
import ClientRouter from './parts/client'
import ServicesRouter from './parts/services'
import Setting from './parts/setting'
import PageControll from './parts/page'
import VacancyCategories from './parts/vacancy_categories'
import VacancyRouter from './parts/vacancy'
import SubscribersRoute from './parts/subscribers'
import PostComments from "./parts/comments";

const routes = [

    ...AuthRouter,
    {

        name: 'admin',
        path: '/admin',
        component: DashboardLayout,
        redirect: '/admin/cases',
        meta: {
            auth: true,
            role: ['admin'],
        },
        children: [
            {
                path: 'icons',
                component: Icons
            },
            ...CaseRouter,
            ...UserRouter,
            ...CategoryRouter,
            ...TagRouter,
            ...ClientRouter,
            ...ServicesRouter,
            ...Setting,
            ...PageControll,
            ...VacancyCategories,
            ...VacancyRouter,
            ...SubscribersRoute,
            ...PostComments
        ]
    },
    {path: '/forbidden', component: Error500, name: 'forbidden'},
    {path: '*', component: NotFound}
]

/**
 * Asynchronously load view (Webpack Lazy loading compatible)
 * The specified component must be inside the Views folder
 * @param  {string} name  the filename (basename) of the view to load.
 function view(name) {
   var res= require('../components/Dashboard/Views/' + name + '.vue');
   return res;
};**/


// configure router
const router = new VueRouter({
    mode: 'history',
    routes, // short for frontend: frontend
    linkActiveClass: 'nav-item active',
    scrollBehavior: (to) => {
        if (to.hash) {
            return {selector: to.hash}
        } else {
            return {x: 0, y: 0}
        }
    }
});


function redirect(cb, arg) {
    cb(arg);

}


router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.auth)) {
        if ((!store.getters['auth/loggedIn'])) {
            redirect(next, {name: 'login'})
            return
        }

        //console.log(store.getters['auth/loggedIn'].data.roles);

        if (to.meta || false) {
            if (to.meta.roles || false) {
                if (store.getters['auth/loggedIn'].data.roles.filter(value => -1 !== to.meta.roles.indexOf(value.name)).length == 0) {
                    redirect(next, {name: 'forbidden'})
                    return;
                }
            }
        }
    }


    redirect(next)
})


export default router
