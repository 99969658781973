<template>
  <card>
    <div class="row align-items-center justify-content-between mb-3">
      <div class="col-auto">
        <h4 class="card-title my-0">Шапка сайта</h4>
      </div>
      <div class="col-auto">
        <button @click="save($event)" class="btn btn-sm btn-success btn-fill pull-right">
          <span class="spinner-border d-none spinner-border-sm" role="status" aria-hidden="true"></span>
          <span class="">Сохранить</span>
        </button>
      </div>
    </div>
    <form>
      <base-input type="text" label="Телефон 1" placeholder="Телефон 1" v-model="tels[0]"/>
      <base-input type="text" label="Телефон 2" placeholder="Телефон 2" v-model="tels[1]"/>
    </form>
  </card>
</template>
<script>
import Card from './../../../components/Cards/Card.vue'
import {addBtnLoading, removeBtnLoading} from './../../../helpers/btnloading'

export default {
  data() {
    return {
      tels  : ['', ''],
      errors: ['', '']
    }
  },
  computed  : {},
  components: {
    Card
  },
  created() {
    axios.get('admin/setting/header').then(({data}) => {
      console.log(data)
      this.tels = (data.data.value != null && Array.isArray(data.data.value)) ? data.data.value : ['', ''];
      //this.$set('$data', 'tels', this.tels);
    })
  },
  methods: {
    save(e) {
      addBtnLoading(e)
      this.errors = [];
      axios.put('admin/setting/header', {key: 'header', value: this.tels}).then(({data}) => {
        removeBtnLoading(e)
        window.notification.success('Сохранение прошло успешно');
      }).catch(error => {
        removeBtnLoading(e)
        this.loading = false;
        this.errors = error.response.data.errors
        window.notification.error(this.errorMessage);
      });
    }
  }
}
</script>
<style>

</style>
