/* eslint-disable camelcase,no-undef */
import {store} from '../vuex/store'
import router from '../routes/routes'

class Auth {
    /** Restore session after reload page */
    constructor() {
        let token = window.localStorage.getItem('access_token') ? window.localStorage.getItem('access_token') : window.sessionStorage.getItem('access_token')
        this.token = token;
        if (token) {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
            this.getUser()
        }

        this.setRefreshInterceptor();
    }

    getToken() {
        let token = window.localStorage.getItem('access_token') ? window.localStorage.getItem('access_token') : window.sessionStorage.getItem('access_token')
        return token;
    }

    /** Set Refresh token Interceptor for axios */
    setRefreshInterceptor() {
        axios.interceptors.response.use(function (response) {
            return response
        }, function (error) {

            console.log(error);
            try {
                if (error.response.status === 403) {
                    console.log('forbidden ...');
                    auth.logout();
                }
            } catch (e) {

            }

            const originalRequest = error.config;
            return Promise.reject(error)
        })
    }

    setCredentionalUser(storage, data) {
        return new Promise((resolve, reject) => {
            window[storage].setItem('access_token', data.token)
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + data.token
            this.getUser().then(() => {
                resolve()
            })
        });
    }

    login(data) {
        let storage = data.remember ? 'localStorage' : 'sessionStorage';
        return new Promise((resolve, reject) => {
            axios.post('/login', data)
                .then(({data}) => {
                    this.setCredentionalUser(storage, data)
                        .then(() => {
                            resolve();
                        })

                })
                .catch(err => {
                    reject(err)
                })
        })
    }

    logout() {
        axios.post('/logout');
        window.localStorage.removeItem('access_token')
        window.localStorage.removeItem('refresh_token')
        window.sessionStorage.removeItem('access_token')
        window.sessionStorage.removeItem('refresh_token')
        store.commit('auth/logout')
        router.push({name: 'login'})
    }

    getUser() {
        return new Promise((resolve, reject) => {
            axios.get('/get-user').then(({data}) => {
                store.commit('auth/setUser', data);
                window.willBeChanges = false;
                resolve()
            }).catch(e => {
                this.logout()
            })
        })
    }

    check() {
        return store.getters['auth/loggedIn']
    }

    static isAdmin() {
        if (_.findIndex(store.getters['auth/loggedIn'].data.roles, function (o) {
            return o.name == 'admin';
        }) === -1) {
            return false;
        }

        return true;
    }

}

export default Auth
