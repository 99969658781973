require("babel-polyfill");

import Paginatation from "./components/Paginatation";

require('./bootstrap')

import Vue from 'vue'

Vue.config.productionTip = false
Vue.config.silent = true
import App from './App.vue'
import router from './routes/routes'
import {store} from './vuex/store'
import Auth from './helpers/auth'
import './helpers/btnloading'
import Preloader from './components/Preloader'
import PreloaderTable from './components/PreloaderTable'


// LightBootstrap plugin
import LightBootstrap from './light-bootstrap-main'




import draggable from 'vuedraggable'

Vue.component('draggable', draggable)


import './registerServiceWorker'
// plugin setup

import vSelect from 'vue-select'

Vue.component('v-select', vSelect)

Vue.use(LightBootstrap)


import VuejsDialog from 'vuejs-dialog';


// include the default style
import 'vuejs-dialog/dist/vuejs-dialog.min.css';


import Moment from 'moment'

Vue.prototype.$moment = Moment;


// Tell Vue to install the plugin.
Vue.use(VuejsDialog);

window.auth = new Auth()

Vue.component('app-preloader', Preloader)
Vue.component('app-pagination', Paginatation)
Vue.component('app-preloader-table', PreloaderTable)

window._ = require('lodash')

import BootstrapVue from 'bootstrap-vue'

Vue.use(BootstrapVue)

Vue.prototype._ = _;

window.bus = new Vue({
    data: {
        canNewRoute: true
    }
});

import Autocomplete from 'vuejs-auto-complete'

Vue.component('autocomplete', Autocomplete)

import VueSidebarMenu from 'vue-sidebar-menu'
import 'vue-sidebar-menu/dist/vue-sidebar-menu.css'


Vue.use(VueSidebarMenu)

 window.notification = new Vue({
    mounted() {

    },
    methods: {
        error(message) {
            this.$notifications.notify(
                {
                    message: message,
                    icon: 'nc-icon nc-app',
                    horizontalAlign: 'bottom',
                    verticalAlign: 'right',
                    type: 'danger'
                })
        },
        success(message) {
            this.$notifications.notify(
                {
                    message: message,
                    icon: 'nc-icon nc-app',
                    horizontalAlign: 'bottom',
                    verticalAlign: 'left',
                    type: 'primary'
                })
        }
    }
});

/* eslint-disable no-new */
new Vue({
    el: '#app',
    render: h => h(App),
    router,
    store
})

