<template>
  <div class="wrapper">
    <sidebar-menu width="260px" :menu="menu" :collapsed="collapsed" :theme="selectedTheme" :show-one-child="true" @collapse="onCollapse" @item-click="onItemClick"/>
    <div class="main-panel d-flex flex-column" :style="'width:calc(100% - '+px+'px);transition:width 0.3s'">
      <top-navbar/>
      <dashboard-content clas="content flex-grow-1" @click="toggleSidebar"/>
      <content-footer/>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.main-panel {
  max-height : inherit;
  height     : auto;
}
</style>
<script>
import TopNavbar from './TopNavbar.vue'
import ContentFooter from './ContentFooter.vue'
import DashboardContent from './Content.vue'
import MobileMenu from './MobileMenu.vue'
import {mapGetters} from 'vuex'

export default {
  components: {
    TopNavbar,
    ContentFooter,
    DashboardContent,
    MobileMenu
  },
  computed  : {
    ...mapGetters('auth', {
      user: 'loggedIn'
    }),
    menu() {
      var menu = [];
      var flag = true;
      _.map(this.menus, (item) => {
        flag = true;
        if (item.meta || false) {
          if (item.meta.roles || false) {
            //console.log(item.meta.roles);
            //console.log(this.user.data.roles);
            if (this.user.data.roles.filter(value => -1 !== item.meta.roles.indexOf(value.name)).length == 0) {
              flag = false;
            }
          }
        }

        if (flag) {
          menu.push(item);
        }
      })

      return menu;
    }
  },
  data() {
    return {
      px           : 260,
      menus        : [
        {
          header: true,
          title : 'WITS'
        },
        {
          href : '/admin/cases',
          title: 'Блог',
          icon : 'fa fa-newspaper-o',
          child: [
            {
              href : '/admin/cases/category',
              title: 'Категории',
              icon : 'fa fa-list-alt'
            },
            {
              href : '/admin/cases/tag',
              icon : 'fa fa-tags',
              title: 'Теги'
            },
            {
              href : '/admin/cases/comments',
              icon : 'fa fa-newspaper-o',
              title: 'Комментарии'
            }
          ]
        },
        {
          meta : {
            roles: ['admin']
          },
          title: 'Страницы',
          icon : 'fa fa-newspaper-o',
          child: [
            {
              href : '/admin/page/home',
              title: '--Главная',
            },
            {
              href : '/admin/page/about',
              title: '--О нас',
            },
            {
              href : '/admin/page/blog',
              title: '--Блог',
            },
            {
              href : '/admin/page/clients',
              title: '--Клиенты',
            },
            {
              href : '/admin/page/contacts',
              title: '--Контакты',
            },
            {
              href : '/admin/page/careers',
              title: '--Карьера',
            }
          ]
        },
        {
          meta : {
            roles: ['admin']
          },
          href : '/admin/users',
          title: 'Пользователи',
          icon : 'fa fa-user-circle'
        },
        {
          meta : {
            roles: ['admin']
          },
          href : '/admin/clients',
          title: 'Клиенты',
          icon : 'fa fa-users'
        },
        {
          meta : {
            roles: ['admin']
          },
          href : '/admin/subscribers',
          title: 'Подписчики',
          icon : 'fa fa-users'
        },
        {
          meta : {
            roles: ['admin']
          },
          title: 'Услуги',
          icon : 'fa fa-handshake-o',
          href : '/admin/services/',
          child: [
            {
              href : '/admin/services/ppc/edit',
              title: 'Google Ads',
              icon : 'fa fa-check'
            }, {
              href : '/admin/services/seo/edit',
              icon : 'fa fa-check',
              title: 'SEO'
            },
            {
              href : '/admin/services/smm/edit',
              icon : 'fa fa-check',
              title: 'SMM'
            }
          ]
        },
        {
          meta : {
            roles: ['admin']
          },
          title: 'Вакансии',
          icon : 'fa fa-file-pdf-o',
          child: [
            {
              href : '/admin/vacancy/category',
              title: 'Категории',
              icon : 'fa fa-check'
            },
            {
              href : '/admin/vacancy/',
              title: 'Список',
              icon : 'fa fa-check'
            }, {
              href : '/admin/vacancy/response',
              icon : 'fa fa-check',
              title: 'Отклики'
            }
          ]
        },
        {
          meta : {
            roles: ['admin']
          },
          title: 'Настройки',
          icon : 'fa fa-cog',
          child: [
            {
              href : '/admin/settings/header',
              title: 'Хедер',
              icon : 'fa fa-check'
            }, {
              href : '/admin/settings/footer/',
              icon : 'fa fa-check',
              title: 'Футер'
            },
            // {
            //     href: '/admin/settings/blocklink/',
            //     icon : 'fa fa-check',
            //     title : 'Футер - Блок перелинковки'
            // },
            {
              href : '/admin/settings/robot',
              icon : 'fa fa-check',
              title: 'robot.txt'
            },
            {
              href : '/admin/settings/head',
              icon : 'fa fa-check',
              title: '<HEAD />'
            }, {
              href : '/admin/settings/sitemap/',
              icon : 'fa fa-check',
              title: 'Sitemap'
            }
          ]
        },
      ],
      collapsed    : false,
      themes       : ['', 'default-theme'],
      selectedTheme: 'default-theme'
    }
  },
  mounted() {

  },
  watch  : {
    collapsed() {
      this.px = this.collapsed ? 50 : 260;
    }
  },
  methods: {
    onCollapse(collapsed) {
      this.collapsed = collapsed
    },
    onItemClick(event, item) {

    },
  }
}

</script>
<style>
.v-sidebar-menu {
  width: 260px;
  background-image: url(/img/sidebar-5.jpg);
}

.v-sidebar-menu:after {
  display: block;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  opacity: 0.33;
  background: #000000;
}

.v-sidebar-menu .collapse-btn {
  position: relative;
  z-index: 3;
}

.vsm-list {
  position: relative;
  z-index: 2;

}

.v-sidebar-menu .vsm-dropdown > .vsm-list {
  background-color: transparent !important;
}
</style>
